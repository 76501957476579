/* POPPINS */

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/subsetted/Poppins-Regular-subset.woff2') format('woff2'),
    url('/fonts/Poppins-Regular.woff') format('woff'),
    url('/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/subsetted/Poppins-Medium-subset.woff2') format('woff2'),
    url('/fonts/Poppins-Medium.woff') format('woff'),
    url('/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/subsetted/Poppins-SemiBold-subset.woff2') format('woff2'),
    url('/fonts/Poppins-SemiBold.woff') format('woff'),
    url('/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/subsetted/Poppins-Bold-subset.woff2') format('woff2'),
    url('/fonts/Poppins-Bold.woff') format('woff'),
    url('/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* PLAYFAIR DISPLAY */

@font-face {
  font-family: 'Playfair Display';
  src: url('/fonts/PlayfairDisplay-BlackItalic.woff') format('woff'),
    url('/fonts/PlayfairDisplay-BlackItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('/fonts/PlayfairDisplay-Bold.ttf') format('truetype'),
    url('/fonts/subsetted/PlayfairDisplay-BlackItalic-subset.woff2')
      format('woff2'),
    url('/fonts/PlayfairDisplay-BlackItalic.woff') format('woff'),
    url('/fonts/PlayfairDisplay-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
